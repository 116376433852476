.App {
  text-align: center;
}

.App .body-main{
  margin-top: 60px;
}

img:-moz-broken{
  opacity: 0;
  background-color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.container-image{
  margin-top: 150px;
}
.container-image img{
  border-radius: 20px;
  width: 400px;
  height: 360px;
}

@media only screen and (max-width: 600px) {
  .container-image img{
    margin-top: 50px;
    width: 300px;
    height: 240px;
  }
}