.disp{
    display: flex;
    justify-content: center;
    margin-bottom: 40px !important;
}
.blog-body{
    width: 60%;
    background-color: white;
    border-radius: 20px;
    padding-bottom: 40px;
    padding: 20px;
}

.blog-body div img{
    width: 100%;
    border-radius: 20px 20px 10px 10px;
    margin-bottom: 20px;
}

.blog_contain{
    padding: 20px;
    color: gray;
    font-style: italic;
}

.action-bar .del{
    color: gray ;
}

.action-bar .edit{
    color: gray ;
}

.action-bar{
    display: flex;
    flex-direction: row;
}

.action-bar .edit:hover{
    color:cornflowerblue;
}

.action-bar .del:hover{
    color:brown;
    cursor: pointer;
}

@media only screen and (max-width: 600px){
    .blog-body{
        width: 90%;
        padding: 10px;
        margin-bottom: 40px !important;
    }
}

.view_me{
    width: 100%;
    resize: none;
    height: 700px;
    border: none;
    outline: none;
    font-family: inherit;
    font-size: medium;
}

#likebtn{
    color: gray;
}

.sideminitab{
    margin-left: 75%;
    margin-top: 20px;
    width: 140px;
    background-color: white;
    height: 280px;
    border-radius: 20px;
    text-align: center;
    position: fixed;
}

.sideminitab .sidiv{
    margin-left: 40px;
} 

.sideminitab h1{
    font-size: 30px;
    font-weight: 500;
}

.comment-box h1{
    height: 20px;
    color: rgb(117, 117, 117);

}
.comment-box hr{
    background-color: rgb(0, 137, 139);
    height: 2px;
    width: 100%;
    margin-bottom: 20px;
}

.comment-box{
    margin-left: 10%;
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

@media only screen and (max-width: 600px){
    .sideminitab{
        display: none;
    }
}
.commentsend{
    text-align: center;
}

.commentsend input{
    padding-left: 10px;
    padding-right: 10px;
    height: 35px;
    width: 65%;
    display: inline;
}
.commentsend button{
    margin-left: 5px;
    height: 40px;
    display: inline;
    margin-bottom: 40px;
}